.promotion-page{
    .sub-banner{
        position: relative;
        background-image: url('/images/promotions-banner.jpg');

        &:not(.community-events-banner){
            &::before {
                content: "";
                position: absolute;
                z-index: 0;
                display: block;
                width: 100%;
                height: 140%;
                top: -40%;
                background: rgba(19, 77, 125, 0.5);
            }

            > * {
                z-index: 1;
                position: relative;
            }
        }
        &.community-events-banner {
            background-image: none !important;
        }
    }
    .banner-slider{
        max-width: rem-calc(810);
        margin: 0 auto rem-calc(30);

        @include breakpoint(large){
            margin: 0 auto rem-calc(90);
        }
    }

    .additional-content-area {
        padding: rem-calc(30 15);

        &.extra-content_2 {
            background: url('/images/promotions-finance-bg.jpg') center;
            background-size: cover;
            color: $white;

            h2, h3, h4, h5, h6{
                text-align: center;
                color: inherit;
            }
            .custom-list li{
                color: inherit;
                background-image: url('/images/image-list-alt.png');
            }
            hr{
                border: 0;
                background: url('/images/logo-wave.png') center no-repeat;
                height: rem-calc(32);
            }
            .button-group {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin-top: 10px;

                > *:not(.button) {
                    @extend h4;
                }
                
                a.button {
                    margin: 0;
                }
            }

            @include breakpoint(medium down) {
                .button-group {
                    flex-direction: column;

                    > *:not(.button) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include breakpoint(large) {
            padding: rem-calc(90 0);

            &.extra-content_1 {
                padding-top: 0;
            }
        }
    }
}

.goals{
    text-align: center;
}

.promotion-callout{
    background: #4CBB17;
    margin: rem-calc(60) auto rem-calc(30);
    padding: rem-calc(0 15 30);
    color: #fff;
    max-width: rem-calc(292);
    img{
        margin-top: rem-calc(-60);
        margin-bottom: rem-calc(15);
    }

    .main-text{
        line-height: 1.1;
        font-family: $header-font-family;
        font-weight: 700;
        font-size: rem-calc(26);
        strong{
            display: block;
            font-size: 2.1em;

            // All-caps text
            em{
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }

    .sub-text{
        font-size: rem-calc(26);
        font-family: $header-font-family;
    }

    .discount{
        line-height: 1.1;
        font-family: $header-font-family;
        font-weight: 700;
        font-size: rem-calc(36);
        strong{
            font-size: rem-calc(57);
        }
    }

    .option{
        font-size: rem-calc(33);
        font-weight: 700;
        font-family: $header-font-family;
    }
}
.promo-description{
    max-width: rem-calc(292);
    margin: 0 auto rem-calc(30);
    text-align: left;

    p{
        color: #333333;
        font-weight: 350;
    }

    span{
        display: block;
        color: #545454;
        font-size: rem-calc(19);
        font-weight: 800;
    }

    .disclaimer{
        font-size: rem-calc(12);
        color: #898989;
    }
}

// Only exists on Promotions Page. Remove if cta is removed.
.additional-cta{
    padding-top: rem-calc(30);
    h3, h4{
        line-height: 1;
        color: #333;
    }
    h3{
        font-family: $body-font-family-bold;
        a{
            display: inline-block;
            text-decoration: none;
        }
    }
}