@charset "UTF-8";

/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies for foundation
@import "vendor/sassy-lists/helpers/missing-dependencies";
@import "vendor/sassy-lists/helpers/true";
@import "vendor/sassy-lists/functions/contain";
@import "vendor/sassy-lists/functions/purge";
@import "vendor/sassy-lists/functions/remove";
@import "vendor/sassy-lists/functions/replace";
@import "vendor/sassy-lists/functions/to-list";

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "partials/foundation-settings";

// Sass utilities
@import "vendor/foundation/util/util";

// Global variables and styles
@import "vendor/foundation/global";

// Components
@import "vendor/foundation/grid/grid";
// @import 'vendor/foundation/xy-grid/xy-grid';
@import "vendor/foundation/typography/typography";
@import "vendor/foundation/forms/forms";
@import "vendor/foundation/components/visibility";
@import "vendor/foundation/components/float";
@import "vendor/foundation/components/button";
// @import 'vendor/foundation/components/button-group';
// @import 'vendor/foundation/components/accordion-menu';
// @import 'vendor/foundation/components/accordion';
@import "vendor/foundation/components/badge";
// @import 'vendor/foundation/components/breadcrumbs';
@import "vendor/foundation/components/callout";
// @import 'vendor/foundation/components/card';
@import "vendor/foundation/components/close-button";
@import "vendor/foundation/components/drilldown";
@import "vendor/foundation/components/dropdown-menu";
@import "vendor/foundation/components/dropdown";
@import "vendor/foundation/components/flex";
@import "vendor/foundation/components/responsive-embed";
// @import 'vendor/foundation/components/label';
@import "vendor/foundation/components/media-object";
@import "vendor/foundation/components/menu";
@import "vendor/foundation/components/menu-icon";
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
@import "vendor/foundation/components/pagination";
// @import 'vendor/foundation/components/progress-bar';
@import "vendor/foundation/components/reveal";
@import "vendor/foundation/components/slider";
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import "vendor/foundation/components/table";
// @import 'vendor/foundation/components/tabs';
@import "vendor/foundation/components/title-bar";
@import "vendor/foundation/components/top-bar";
@import "vendor/foundation/components/thumbnail";
// @import 'vendor/foundation/components/tooltip';
// @import 'vendor/foundation/prototype/prototype';

@mixin foundation-everything($flex: true, $prototype: false) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;

  @if not $flex {
    @include foundation-grid;
  }

  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }

    @else {
      @include foundation-flex-grid;
    }
  }

  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  // @include foundation-accordion;
  // @include foundation-accordion-menu;
  @include foundation-badge;
  // @include foundation-breadcrumbs;
  // @include foundation-button-group;
  @include foundation-callout;
  // @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  // @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  @include foundation-slider;
  // @include foundation-sticky;
  @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  // @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-everything();

@import "partials/fonts";
@import "partials/typography";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// jQuery UI
@import "vendor/jquery-ui/jquery-ui.min";
@import "vendor/jquery-ui/jquery-ui.combobox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
// @import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";

/*=======================================
          HLM Modules
=======================================*/
@import "partials/module-includes";

// Banner styles
@import "modules/banner/_banner_full-width";

// Gallery Options
@import "modules/gallery/ThumbnailLayout";
// @import "modules/gallery/ThumbnailSlider";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";
@import "sections/social-calendar";

// HLM Other
@import "partials/accordions";
@import "partials/coupons";
@import "partials/blog-grid";
@import "partials/communityname-search";
// @import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/hopscotch";
@import "partials/myce-callouts";
@import "partials/subnav";
// @import "partials/products";
// @import "partials/food-menus";
@import "partials/search";
@import "partials/sml";
@import "partials/community";
@import "partials/property";
@import "partials/promotions";
@import "partials/find-a-community";
@import "partials/grid-banner";
@import "partials/community-events";

// Enhanced Subpage styles
@import "partials/enhanced-subpage";

/*=======================================
      Custom Site Styles
=======================================*/
p.disclaimer {
  color: #898989;
  font-size: rem-calc(12);
  font-weight: 350;
  text-align: center;
}

.additional-content-area {
  position: relative;

  // Last content section
  &.find-a-home {
    background-color: #fff;
  }

  // Wrapper for messaging
  .content-wrapper {
    padding: rem-calc(30);

    &>*:last-child {
      margin-bottom: 0;
    }
  }

  // Array of background colors and images for content sections
  $style-collection: (
    "play",
    "#759a03",
    "url(/images/home-section-room-to-play.jpg)"
    ),
    ("grow", "#1a8ce0", "url(/images/home-section-room-to-grow.jpg)"),
    ("garden", "#e76526", "url(/images/home-section-room-to-garden.jpg)"),
    ("friends", "#4060A9", "url(/images/home-section-room-for-friends.jpg)"),
    ("pets", "#754f39", "url(/images/home-section-room-for-pets.jpg)"),
    ("saving", "#4785b9", "url(/images/home-section-room-for-saving.jpg)"
  );

// Iterate over each section
@each $name, $color, $image in $style-collection {
  &.square-color-#{unquote($name)} {
    background-color: unquote($color);

    h2,
    p {
      color: $white;
    }

    .button {
      color: #fff;
      border-color: #fff;
    }

    // Images on tablet/mobile
    .columns.hide-for-large {
      min-height: rem-calc(220);
      background-image: unquote($image);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      pointer-events: none;
    }
  }

  @include breakpoint(medium) {
    &.square-color-#{unquote($name)} {
      .columns.hide-for-large {
        min-height: rem-calc(360);
      }
    }
  }

  @include breakpoint(large) {

    // Last content section
    &.find-a-home {
      padding: rem-calc(90 10);

      // Image
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        background-image: url(/images/home-section-find-your-new-home.jpg);
        background-repeat: no-repeat;
        background-position: 50% 0%;
        background-size: cover;
        pointer-events: none;
      }
    }

    // Sections with color
    &.square-color-#{unquote($name)} {
      padding: rem-calc(90 10);

      .columns {
        &::before {
          display: block;
          position: absolute;
          height: 100%;
          width: 50%;
          top: 0;
          background-image: unquote($image);
          background-repeat: no-repeat;
          background-size: cover;
          pointer-events: none;
        }

        // Content on the left side
        &.end {
          &::before {
            right: 0;
            background-position: 50% 100%;
          }
        }

        // Content on the right side
        &.large-offset-6 {
          &::before {
            left: 0;
            background-position: 50% 0%;
          }
        }
      }
    }
  }
}
}

.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}

.contact-cta {
  padding: rem-calc(30 0);
  border-top: 1px solid #ccc;

  h2 {
    margin-bottom: rem-calc(30);
    font-size: rem-calc(30);
  }

  .button {
    display: block;
    width: 100%;
  }

  span {
    display: block;
    margin-bottom: rem-calc(30);
    padding: rem-calc(0 30);
    text-transform: uppercase;
    color: $primary-color;
    font-weight: 600;
    font-size: rem-calc(22);
  }

  @include breakpoint(medium) {
    padding: rem-calc(60 0);

    h2 {
      font-size: rem-calc(44);
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        width: auto;
      }
    }
  }
}

.filter-list .filter {
  margin-bottom: rem-calc(10);

  input {
    display: none;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: rem-calc(10 20);
    margin: 0;
    background: #eee;
    border-radius: rem-calc(30);
    line-height: 1.5;

    @media (pointer: fine) {
      &:hover {
        background: #418bfe;
      }
    }
  }

  :checked+label {
    color: #fff;
    background: $primary-color;
  }
}

.filter-list {
  margin: auto;
  list-style: none;

  .filter .badge {
    margin-left: rem-calc(5);
    line-height: 1.5;
    font-weight: 500;
  }
}

.filters-clear {
  display: none;
}

.markets-filter {
  .accordion-slide {
    border: 1px solid #eee;
  }
}

.filter label {
  font-size: rem-calc(16);
}

#locations-filters-title {
  color: inherit;
  margin: 0;
}

#locations-filters-clear {
  margin-top: rem-calc(15);
}

#gallery-thumb-slider {
  position: relative;

  img {
    width: 100%;
  }

  .flex-viewport li a {
    display: block;
    position: relative;
    height: rem-calc(200);
    overflow: hidden;
    outline: 10px solid rgba(255, 255, 255, 0.16);
    outline-offset: -10px;
    margin-bottom: rem-calc(20);
  }

  .flex-direction-nav a {
    top: calc(50% - 15px);
    opacity: 1;
    height: 40px;

    &::before {
      content: "\e5e1";
      font-size: 30px;
      padding: 0 4px;
      text-shadow: 0 0 4px rgba($black, .4);
      color: $white;
      font-family: "Material Icons";
    }

    &.flex-next {
      right: rem-calc(20);
    }

    &.flex-prev {
      text-align: left;
      left: rem-calc(20);

      &::before {
        content: "\e5e0";
      }
    }
  }
}

// Communities/VirtualTour heading
// alignment adjustments from client.
#page-id-79,
#page-id-80 {
  .content h1 {
    margin: rem-calc(0 15 20);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(20);

      &+hr {
        margin-bottom: rem-calc(40);
      }
    }

    @include breakpoint(large) {
      margin-bottom: rem-calc(30);

      &+hr {
        margin-bottom: rem-calc(70);
      }
    }
  }
}

// VirtualTour Buttons
#page-id-80 {
  .content-text {
    .row.align-center.align-middle {
      text-align: center;
    }
  }
}

.reveal-overlay .reveal {
  padding-bottom: rem-calc(20);

  a.button {
    margin-bottom: 0;
  }
}

.planned-maintenance {
  header.page-header {
    background-color: rgba(0, 76, 138, 0.7);
  }

  br {
    display: none;
  }

  img.logo {
    margin-top: rem-calc(15);
  }

  @include breakpoint(large) {
    .content-text {
      padding: rem-calc(100 0);
    }

    br {
      display: inline-block;
    }

    footer.page-footer.basic {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
